<template>
  <div class="d-flex flex-column flex-grow-1">
    <div
      class="d-flex align-center"
      v-if="!isNew"
    >
      <v-btn
        class="connect-btn"
        rounded
        justify-center
        color="primary"
        @click="subscribeEvents()"
        v-ripple
        :loading="testConnectWorking"
      >
        <span v-if="!this.computedSubscriptionEvents">{{$t('t.MailevaSubscriptionEvents')}}</span>
        <span v-else>{{$t('t.MailevaRefreshWebhook')}}</span>
      </v-btn>
      <div v-if="this.computedSubscriptionEvents">
        <v-icon
          class="mb-1 ml-4"
          color="success"
        >{{$icon('i.Checked')}}</v-icon>
        <span class="ml-2 title success--text"> {{$t('t.MailevaWebhookActive')}}</span>
      </div>
    </div>
    <div class="d-flex flex-column mt-4">
      <v-text-field
        class="field"
        :label="$t('t.ClientId')"
        v-model="computedClientId"
        :rules="ruleRequiredMax200"
        :clearable="true"
      />
      <v-text-field
        class="field"
        :label="$t('t.ClientSecret')"
        v-model="computedClientSecret"
        :type="'password'"
        :rules="ruleRequiredMax200"
        :clearable="true"
      />
      <v-text-field
        class="field"
        :label="$t('t.Username')"
        v-model="computedUsername"
        :rules="ruleRequiredMax200"
        :clearable="true"
      />
      <v-text-field
        class="field"
        :label="$t('t.Password')"
        v-model="computedPassword"
        :type="'password'"
        :rules="ruleRequiredMax200"
        :clearable="true"
      />
    </div>
    <v-btn
      v-if="!isNew"
      class="connect-btn mt-2"
      rounded
      justify-center
      color="primary"
      @click="testConnect()"
      v-ripple
      :loading="testConnectWorking"
    >
      <span>{{$t('t.TestConnection')}}</span>
    </v-btn>
    <v-bottom-sheet
      v-model="showError"
      persistent
      v-if="error"
      :hide-overlay="false"
    >
      <v-sheet
        class="text-center"
        height="200px"
      >
        <v-btn
          class="mt-6"
          color="error"
          @click="closeError()"
        >
          {{$t('t.Ok')}}
        </v-btn>
        <div class="py-3 h6">
          {{error.title}}
        </div>
        <div class="py-3 body-2">
          {{error.message}}
        </div>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script>

export default {
  components: {
  },
  data () {
    return {
      testConnectWorking: false,
      error: null,
      showError: false,
      required: [v => (!!v && !this.lodash.isEqual(v, { id: null })) || this.$t('t.IsRequired')],
      dataDocumentEmit: this.$nextTickDedup(this.emitDocument),
      dataClientId: null,
      dataClientSecret: null,
      dataPassword: null,
      dataUsername: null,
      passwordChanged: false,
      clientSecretChanged: false
    }
  },
  computed: {
    canTest () {
      if (!this.dataClientId || !this.dataClientSecret) { return false }
      if (this.isDirty) { return false }

      return true
    },
    ruleRequiredMax200 () {
      return [
        v => !!v || this.$t('t.IsRequired'),
        v => (v ?? '').length <= 200
      ]
    },
    ruleMax200 () {
      return [v => (v ?? '').length <= 200]
    },
    computedSubscriptionEvents () {
      return this.document?.v?.subscriptionEvents
    },
    computedClientId: {
      get () {
        return this.dataClientId
      },
      set (v) {
        this.dataClientId = v
        this.dataDocumentEmit()
      }
    },
    computedClientSecret: {
      get () {
        return this.dataClientSecret
      },
      set (v) {
        this.clientSecretChanged = this.dataClientSecret !== v
        this.dataClientSecret = v
        this.dataDocumentEmit()
      }
    },
    computedUsername: {
      get () {
        return this.dataUsername
      },
      set (v) {
        this.dataUsername = v
        this.dataDocumentEmit()
      }
    },
    computedPassword: {
      get () {
        return this.dataPassword
      },
      set (v) {
        this.passwordChanged = this.dataPassword !== v
        this.dataPassword = v
        this.dataDocumentEmit()
      }
    }
  },
  methods: {
    closeError () {
      this.showError = false
      this.error = null
    },
    emitDocument () {
      const doc = {
        ty: 'maileva',
        v: {
          clientId: this.dataClientId,
          clientSecret: { password: this.dataClientSecret, passwordChanged: this.clientSecretChanged, providerId: this.dataProviderId },
          encryptKey: this.dataEncryptKey,
          password: { password: this.dataPassword, passwordChanged: this.passwordChanged, providerId: this.dataProviderId },
          username: this.dataUsername
        }
      }

      if (!this.lodash.isEqual(this.value, doc)) {
        this.$emit('update:document', this.lodash.cloneDeep(doc))
      }
    },
    async testConnect () {
      this.testConnectWorking = true

      const s = this.document.v

      await this.$http().post('/core/providers/maileva/test', s)
        .then(result => {
          const r = result.data
          if (r.hasError) {
            this.error = {
              title: this.$t('t.Snackbar.TestConnectionError'),
              message: r.message
            }
            this.showError = true
          } else {
            this.$store.dispatch('showSuccessSnackbar', this.$t('t.Snackbar.SuccessConfirmation', { title: this.$t('t.TestConnection') }))
          }
        })
        .catch(e => this.$store.dispatch('showErrorSnackbar', e.response?.data?.message))
        .finally(e => {
          this.testConnectWorking = false
        })
    },
    async subscribeEvents () {
      this.testConnectWorking = true

      await this.$http().post(`/core/providers/${this.$route.params.id}/maileva/subscribe-events`)
        .then(_ => this.$store.dispatch('showSuccessSnackbar', this.$t('t.Snackbar.SuccessConfirmation', { title: this.$t('t.MailevaWebhookActive') })))
        .catch(e => this.$store.dispatch('showErrorSnackbar', e.response?.data?.message))
        .finally(e => {
          this.testConnectWorking = false
        })
    }
  },
  props: {
    clearable: {
      type: Boolean,
      default: false
    },
    document: Object,
    isDirty: {
      type: Boolean,
      default: false
    },
    isNew: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    document: {
      immediate: true,
      handler (d) {
        const v = d?.v
        this.dataProviderId = v?.password?.providerId
        this.dataEncryptKey = v?.encryptKey
        this.dataClientId = v?.clientId
        this.dataClientSecret = v?.clientSecret?.password
        this.dataPassword = v?.password?.password
        this.dataUsername = v?.username
      }
    },
    isDirty: {
      immediate: true,
      handler (v) {
        if (!v) {
          this.passwordChanged = false
          this.clientSecretChanged = false
        }
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.field
  max-width 30%

.connect-btn
  max-width 300px
</style>
